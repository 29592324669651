.App {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("template.avif");
  background-repeat: no-repeat;
  background-size: cover;
}

.login-container {
  background-color: white;
  padding: 40px; 
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
  text-align: center;
}

.input-field {
  width: 100%;
  margin-bottom: 20px; 
  padding: 12px; 
  border: 1px solid #ccc;
  border-radius: 8px; 
  font-size: 16px;  
  box-sizing: border-box;
}

.google-btn {
  width: 100%;
  padding: 15px; 
  background-color: #db4437; 
  color: white;
  border: none;
  border-radius: 8px; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
}

.google-btn:hover {
  background-color: #c13584; 
}

.logout-btn {
  margin-top: 30px; 
  padding: 15px 30px; 
  background-color: #4285f4; 
  color: white;
  border: none;
  border-radius: 8px; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
}

.logout-btn:hover {
  background-color: #357ae8; 
}

.greeting {
  font-size: 24px;  
  margin-bottom: 30px; 
}
